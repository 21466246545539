import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { EditAssigmentModal } from "./EditAssigmentModal/EditAssigmentModal";
import { getAllEmployeeTeams } from "../../../../../../Services/TeamServices";
import {
  deleteTBR as deleteTBREndpoint,
  getAllEmployee,
} from "../../../../../../Services/EmployeeConfig";
import { getAllDepartments } from "../../../../../../Services/DepartamentService";
import { AedasLoading } from "../../../../../Shared/AedasLoading/AedasLoading";
import { CustomModal } from "../../../../../Shared/Modals/ModalCustom/CustomModal";

export const EmployeeAssignment = (props: any) => {
  const [employees, setEmployees] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [departments, setDepartaments] = useState<any>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [teams, setAllTeams] = useState<any>(null);
  const [openModalShow, setOpenModalShow] = useState(false);

  const TBR = {
    id: -1,
    url_img: "photos/blank",
    display_name: "TBR",
    name: "",
    id_company: props.company.id,
  };

  const [selectedRow, setSelectedRow] = useState<any>(TBR);
  const [alreadyAssignated, setAlreadyAssignated] = useState<any>(null);

  const deleteTBR = async (id: number, id_mongo: any) => {
    toast.loading("Eliminando TBR...");

    try {
      const response = await deleteTBREndpoint(id, id_mongo);
      toast.dismiss();
      toast.success("TBR eliminado correctamente.");
      // Actualiza el estado después de eliminar el TBR
      setIsLoading(true);
    } catch (error: any) {
      toast.dismiss();
      if (error.response) {
        const { status, data } = error.response;
        if (
          status === 404 &&
          data.detail === "El empleado tiene subordinados"
        ) {
          toast.error(
            "El TBR no se puede eliminar ya que cuenta con personal asignado."
          );
        } else if (status === 500) {
          toast.error("Ha habido algún error al eliminar el TBR.");
        } else {
          toast.error("Error inesperado al eliminar el TBR.");
        }
      } else {
        toast.error("Error de conexión al servidor.");
      }
    }
  };

  useEffect(() => {
    if (isLoading) {
      let promises = [];

      promises.push(
        getAllEmployeeTeams(props.company.id).then((response: any) => {
          setAllTeams(response);
        })
      );
      promises.push(
        getAllDepartments().then((response: any) => setDepartaments(response))
      );

      promises.push(
        getAllEmployee().then((response: any) => {
          setEmployees(
            response.filter((emp: any) => emp.id_company === props.company.id)
          );
        })
      );

      Promise.all(promises).then(() => {
        setIsLoading(false);
      });
    }
  }, [isLoading]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "display_name",
      headerName: "Empleado",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
      valueGetter: (params: GridRenderEditCellParams) => {
        if (params.row.display_name.includes("TBR")) {
          return params.row.display_name + " - " + params.row.azure_rol;
        } else {
          return params.row.display_name;
        }
      },
    },
    {
      field: "id_department",
      headerName: "Departamento",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
      valueGetter: (params: GridRenderEditCellParams) => {
        const depart = departments.find(
          (d: any) => d.id === params.row.id_department
        );
        return depart ? depart.name : "Sin departamento";
      },
    },

    {
      field: "id_manager",
      headerName: "Responsable",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
      valueGetter: (params: GridRenderEditCellParams) => {
        const team: any = teams.find(
          (t: any) => t.id_employee === params.row.id
        );

        let manager_name = "Sin asignación";
        if (typeof team !== "undefined") {
          const manager_emp = employees.find(
            (e: any) => e.id === team.id_manager
          );
          if (typeof manager_emp !== "undefined") {
            manager_name = manager_emp.display_name;
          }
        }

        return manager_name;
      },
    },

    {
      field: "actions",
      headerName: "Acciones",
      renderCell: (params: GridValueGetterParams) => {
        if (params.row.display_name.includes("TBR")) {
          return (
            <>
              <Button
                onClick={(_: any) => handleEdit(params)}
                color="primary"
                className="org_Button"
              >
                Asignación
              </Button>
              <Button
                onClick={(_: any) => {
                  deleteTBR(params.row.id, props.id_mongo);
                }}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover": {
                    backgroundColor: "#f44336",
                  },
                  fontFamily: "var(--lis-title)",
                  fontSize: "10px",
                  color: "white",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Eliminar
              </Button>
            </>
          );
        } else {
          return (
            <Button
              onClick={(e: any) => handleEdit(params)}
              color="primary"
              className="org_Button"
            >
              Asignación
            </Button>
          );
        }
      },
      width: 200,
      flex: 1,
    },
  ];

  const handleEdit = (params: any) => {
    const team: any = teams.find((t: any) => t.id_employee === params.id);
    team ? setAlreadyAssignated(team) : setAlreadyAssignated(null);
    setOpenModalShow(!openModalShow);
  };

  const onCellClick = (params: any) => {
    if (params.field === "actions" && params.row.display_name.includes("TBR")) {
      return; // Do not open modal if "Eliminar" action is clicked
    }
    handleEdit(params);
    setSelectedRow(params.row);
  };

  const handleAddTBR = () => {
    setSelectedRow(TBR);
    setAlreadyAssignated(null);
    setOpenModalShow(!openModalShow);
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div style={{ height: "calc(100vh - 10%)" }}>
          <CustomModal
            size="xl"
            show={openModalShow}
            onChange={() => {
              setOpenModalShow(!openModalShow);
            }}
            title="Asignación"
            body={
              <EditAssigmentModal
                employees={employees}
                company={props.company}
                selectedRow={selectedRow}
                alreadyAssignated={alreadyAssignated}
                teams={teams}
                draftsOptions=""
                id_mongo={props.id_mongo}
                show={() => {
                  setOpenModalShow(!openModalShow);
                }}
                isLoading_OLD={() => setIsLoading(!isLoading)}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isSaving={isSaving}
              />
            }
          />

          <div className="button-container">
            <Button
              className="save_button my-2"
              color="primary"
              onClick={() => handleAddTBR()}
            >
              Asignar TBR
            </Button>
          </div>
          <div className="dataGrid-table--employees">
            <DataGrid
              onCellClick={(event: any) => onCellClick(event)}
              rows={employees}
              columns={columns}
              disableSelectionOnClick={false}
              editMode="cell"
              // getRowId={props.getRowId}
            ></DataGrid>
          </div>
        </div>
      )}
    </>
  );
};
