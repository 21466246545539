/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField, Autocomplete, Typography } from "@mui/material";
import PopupState from "material-ui-popup-state";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import "./EditAssigmentModal.css";
import { useHistory, useParams } from "react-router-dom";
import {
  copyBranch,
  deleteBranch,
} from "../../../../../../../Services/OrganizationChartService";
import {
  addTeamAssignment,
  updateEmployeeLayer,
  updateTeamAssignment,
} from "../../../../../../../Services/EmployeeConfig";
import { norepobj } from "../../../../../../../Utils/filterDuplicates";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { getAllDepartments } from "../../../../../../../Services/DepartamentService";
import { getAllEmployeeTeams } from "../../../../../../../Services/TeamServices";
import { request } from "http";
import { rejects } from "assert";

export const EditAssigmentModal = (props: any) => {
  const [ListorderNumber, setListOrderNumber] = useState<any>([]);
  const { selectedRow, company, alreadyAssignated, employees } = props;
  const [manager, setManager] = useState<null | any>(null);
  const [idsChangesOrder, setIdsChangesOrder] = useState<any[]>([]);
  const [departmentsList, setDepartamentsList] = useState<any>([]);
  const [newManager, setNewManager] = useState<string>("");
  const [newManagerBranch, setNewManagerBranch] = useState<string>("");
  const [rangeUpdateManager, setRangeUpdateManager] = useState<any>();
  const [rango, setRango] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [step, setStep] = useState<number>(0);
  const [isAssitant, setIsAssitant] = useState<boolean>(false);
  const [azure_rol, setAzure_rol] = useState(null);
  const [openModalShow, setOpenModalShow] = useState(false);
  const [newManagerId, setNewManagerId] = useState<null | any>(null);
  const history = useHistory();
  const [preparedShowModalChangeOrder, setPreparedShowModalChangeOrder] =
    useState(false);
  const employeesWithoutThemseft: any = employees.filter(
    (employee: any) => employee.id !== selectedRow.id
  );
  let employeesOptions: any = [];
  // eslint-disable-next-line array-callback-return
  employeesWithoutThemseft.map((element: any, i: number) => {
    employeesOptions.push({
      key: i,
      value: element.display_name + " - " + element.azure_rol,
      label:
        element.display_name === "TBR"
          ? element.display_name + " - " + element.azure_rol
          : element.display_name,
      id: element.id,
    });
  });
  const params: any = useParams();

  const handleAsignOrder = (params: any) => {
    setTimeout(() => {
      const dataParams = params.api.getRow(params.row.id);

      let newManagerIdTemp: number | null = null;

      // Verificar si newManagerBranch contiene el string 'TBR'
      if (newManagerBranch.includes("TBR")) {
        // Separar el string 'TBR' y el nombre restante
        const [branchType, branchName] = newManagerBranch.split(" - ");

        // Filtrar empleados cuyo display_name sea igual a 'TBR'
        const filteredEmployees = props.employees.filter(
          (m: any) => m.display_name === branchType
        );

        if (filteredEmployees.length > 0) {
          const newManagerTBRArray = filteredEmployees.filter(
            (e: any) => e.azure_rol == branchName
          );

          if (newManagerTBRArray.length > 1) {
            const managerWithDeparment = newManagerTBRArray.find(
              (manager: any) =>
                manager.id_department == props.selectedRow.id_department
            );

            newManagerIdTemp = managerWithDeparment?.id;
            setNewManagerId(managerWithDeparment?.id);
          } else {
            newManagerIdTemp = newManagerTBRArray[0]?.id;
            setNewManagerId(newManagerTBRArray[0]?.id);
          }
        }
      } else {
        // Si no contiene "TBR", buscar el ID directamente por display_name
        let new_manager_append_id: number = props.employees.find((m: any) => {
          return m.display_name === newManagerBranch;
        })?.id;

        newManagerIdTemp = new_manager_append_id;
        setNewManagerId(new_manager_append_id);
      }
      if (newManagerIdTemp) {
        copyBranch(
          params.row.id,
          newManagerIdTemp,
          parseInt(dataParams.order),
          company.id,
          props.id_mongo
        ).then((response: any) => {
          if (response) {
            response.data.status =
              //@ts-ignore
              200 && toast.success("Actualizado correctamente");
          }
        });
      }
    }, 500);
  };

  const handleCommitItemOrder = (param: any) => {
    const copyObjectData: any[] = structuredClone(idsChangesOrder);
    copyObjectData.push(param.id);
    setIdsChangesOrder(copyObjectData);
    const updatedListOrderNumber = ListorderNumber.map((item: any) => {
      if (item.id === param.id) {
        item.order = param.value;
      }
      return item;
    });

    setListOrderNumber(updatedListOrderNumber);
  };

  const handleAsignOrderAllEmployeeTeam = () => {
    const promise = new Promise((request, rejects) => {
      let newManagerIdTemp: number | null = null;

      // Verificar si newManagerBranch contiene el string 'TBR'
      if (newManagerBranch.includes("TBR")) {
        // Separar el string 'TBR' y el nombre restante
        const [branchType, branchName] = newManagerBranch.split(" - ");

        // Filtrar empleados cuyo display_name sea igual a 'TBR'
        const filteredEmployees = props.employees.filter(
          (m: any) => m.display_name === branchType
        );

        if (filteredEmployees.length > 0) {
          const newManagerTBRArray = filteredEmployees.filter(
            (e: any) => e.azure_rol == branchName
          );

          if (newManagerTBRArray.length > 1) {
            const managerWithDeparment = newManagerTBRArray.find(
              (manager: any) =>
                manager.id_department == props.selectedRow.id_department
            );

            newManagerIdTemp = managerWithDeparment?.id;
            setNewManagerId(managerWithDeparment?.id);
          } else {
            newManagerIdTemp = newManagerTBRArray[0]?.id;
            setNewManagerId(newManagerTBRArray[0]?.id);
          }
        }
      } else {
        // Si no contiene "TBR", buscar el ID directamente por display_name
        let new_manager_append_id: number = props.employees.find((m: any) => {
          return m.display_name === newManagerBranch;
        })?.id;

        newManagerIdTemp = new_manager_append_id;
        setNewManagerId(new_manager_append_id);
      }
      ListorderNumber.forEach((itemOrder: any) => {
        if (idsChangesOrder.includes(itemOrder.id) && newManagerIdTemp) {
          copyBranch(
            itemOrder.id,
            newManagerIdTemp,
            parseInt(itemOrder.order),
            company.id,
            props.id_mongo
          )
            .then((response: any) => {
              if (response) {
                setIdsChangesOrder([]);
                request("Ha ido bien");
              }
            })
            .catch((error: any) => {
              request("Ha ido mal");
            })
            .finally(() => {
              request(true);
            });
        } else {
          console.error(
            "Error: No se pudo encontrar el ID del nuevo responsable."
          );
        }
      });
    });
    toast.promise(promise, {
      pending: "Actualizando...",
      success: "Actualizado correctamente",
      error: "Error al actualizar",
    });
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "display_name",
      headerName: "Nombre",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div className="w-100" title={params.row.display_name}>
            {params.row.display_name}
          </div>
        );
      },
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "azure_rol",
      headerName: "Rol",
      width: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div className="w-100" title={params.row.azure_rol}>
            {params.row.azure_rol}
          </div>
        );
      },
      editable: false,
      flex: 1,
    },
    {
      field: "id_department",
      headerName: "Departamento",
      renderCell: (params: GridValueGetterParams) => {
        const department: any = departmentsList.find(
          (departmentElement: any) =>
            departmentElement.id === params.row.id_department
        );
        return (
          <div title={department.name} className="w-100">
            {department.name}
          </div>
        );
      },
      width: 200,
      editable: false,
      flex: 1,
    },

    {
      field: "order",
      headerName: "Establece orden",
      width: 150,
      flex: 0,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Acciones",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Button
            onClick={() => {
              handleAsignOrder(params);
            }}
            color="primary"
            className="org_Button"
          >
            Guardar
          </Button>
        );
      },
      width: 200,
      flex: 0,
    },
  ];

  useEffect(() => {
    if (alreadyAssignated) {
      let manager = employees.find(
        (employee: any) => employee.id === alreadyAssignated.id_manager
      );
      setRangeUpdateManager(manager);
      if (manager) {
        const newLabel =
          manager.display_name === "TBR"
            ? manager.display_name + " - " + manager.azure_rol
            : manager.display_name;
        setNewManagerBranch(newLabel);
      } else {
        setNewManagerBranch("");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedRow.order) {
      setOrder(selectedRow.order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const handleCopyBranch = () => {
    let newManagerIdTemp: number | null = null;
    // Verificar si newManagerBranch contiene el string 'TBR'
    if (newManagerBranch.includes("TBR")) {
      // Separar el string 'TBR' y el nombre restante
      const [branchType, branchName] = newManagerBranch.split(" - ");

      // Filtrar empleados cuyo display_name sea igual a 'TBR'
      const filteredEmployees = props.employees.filter(
        (m: any) => m.display_name === branchType
      );

      if (filteredEmployees.length > 0) {
        const newManagerTBRArray = filteredEmployees.filter(
          (e: any) => e.azure_rol == branchName
        );

        if (newManagerTBRArray.length > 1) {
          const managerWithDeparment = newManagerTBRArray.find(
            (manager: any) =>
              manager.id_department == props.selectedRow.id_department
          );

          newManagerIdTemp = managerWithDeparment?.id;
          setNewManagerId(managerWithDeparment?.id);
        } else {
          newManagerIdTemp = newManagerTBRArray[0]?.id;
          setNewManagerId(newManagerTBRArray[0]?.id);
        }
      }
    } else {
      // Si no contiene "TBR", buscar el ID directamente por display_name
      let new_manager_append_id: number = props.employees.find((m: any) => {
        return m.display_name === newManagerBranch;
      })?.id;

      newManagerIdTemp = new_manager_append_id;
      setNewManagerId(new_manager_append_id);
    }

    if (newManagerIdTemp) {
      copyBranch(
        selectedRow.id,
        newManagerIdTemp,
        order,
        company.id,
        props.id_mongo
      ).then(() => {
        props.show(!props.show);
        props.setIsLoading(!props.isLoading);
      });
    } else {
      console.error("Error: No se pudo encontrar el ID del nuevo responsable.");
    }

    setStep(0);
  };

  const handleChangeEdit = (event: any, values: any) => {
    setNewManager(values.label);
  };

  const handleAzureRol = (event: any) => {
    setAzure_rol(event.target.value);
  };

  const handleChangeEditBranch = (event: any, values: any) => {
    setNewManagerBranch(values.label);
  };

  const handleReasign = (e: any) => {
    const newManager_id: any = props.employees.find(
      (m: any) => m.display_name === newManager
    )?.id;

    updateTeamAssignment(
      selectedRow.id,
      company.id,
      props.id_mongo,
      newManager_id
    ).then(() => {
      props.show(!props.show);
      props.setIsLoading(!props.isLoading);
    });

    setStep(0);
  };

  const handleRemove = () => {
    deleteBranch(selectedRow.id, company.id, props.id_mongo).then(() => {
      props.show(!props.show);
      props.setIsLoading(!props.isLoading);
    });

    setStep(0);
  };

  const handleSave = () => {
    const { template_id } = params;
    if (!manager) {
      toast.warning("El manager no puede estar vacio");
    } else if (rango === null && !isAssitant) {
      toast.warning("El rango no puede estar vacio si no es asistente");
    } else {
      if (parseInt(template_id) !== -1) {
        addTeamAssignment(
          manager,
          selectedRow.id,
          rango,
          order,
          company.id,
          template_id,
          isAssitant,
          azure_rol
        ).then(() => {
          props.show(!props.show);
          props.setIsLoading(!props.isLoading);
        });
        setStep(0);
      } else {
        addTeamAssignment(
          manager,
          selectedRow.id,
          rango,
          order,
          company.id,
          template_id,
          isAssitant
        ).then((response: any) => {
          props.setIsLoading(!props.isLoading);
          history.push(
            "/organigramas/borradores/" + company.id + "/" + response.data
          );
        });
        setStep(0);
      }
    }
  };

  const handleChange = (event: any, values: any) => {
    let newManagerIdTemp2: number | null = null;

    if (values?.label.includes("TBR")) {
      // Separar el string 'TBR' y el nombre restante
      const [branchType, branchName] = values.label.split(" - ");

      // Filtrar empleados cuyo display_name sea igual a 'TBR'
      const filteredEmployees = props.employees.filter(
        (m: any) => m.display_name === branchType
      );

      if (filteredEmployees.length > 0) {
        const newManagerTBRArray = filteredEmployees.filter(
          (e: any) => e.azure_rol == branchName
        );

        if (newManagerTBRArray.length > 1) {
          const managerWithDeparment = newManagerTBRArray.find(
            (manager: any) => {
              return manager.id_department == props.selectedRow.id_department;
            }
          );

          newManagerIdTemp2 = managerWithDeparment?.id;
          setManager(managerWithDeparment?.id);
        } else {
          newManagerIdTemp2 = newManagerTBRArray[0]?.id;
          setManager(newManagerTBRArray[0]?.id);
        }
      }
    } else {
      // Si no contiene "TBR", buscar el ID directamente por display_name

      let new_manager_append_id: number = props.employees?.find(
        (m: any) => m.display_name === values.label
      )?.id;

      newManagerIdTemp2 = new_manager_append_id;

      setManager(new_manager_append_id);
    }
    if (newManagerIdTemp2) {
      setManager(newManagerIdTemp2);
    }
  };

  const handleRango = (event: any) => {
    setRango(parseInt(event.target.value));
  };

  /**
   *
   * @param event
   */
  const handleOrder = (event: any) => {
    setOrder(parseInt(event.target.value));
  };

  const handleUpdateRange = () => {
    const { template_id } = params;

    updateEmployeeLayer(selectedRow.id, company.id, template_id, rango).then(
      () => {
        props.show(!props.show);
        props.setIsLoading(!props.isLoading);
      }
    );

    setStep(0);
  };

  const handleAssitantOptions = (e: any) => {
    setIsAssitant(!isAssitant);
  };

  const ButtonEmployeeOptions = () => {
    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button
              className="save_button m-1 employee w-100"
              color="primary"
              onClick={() => setStep(3)}
            >
              Reasignar por otro empleado
            </Button>
          </React.Fragment>
        )}
      </PopupState>
    );
  };

  const Stepper = () => {
    switch (step) {
      case 0:
        return <div></div>;

      case 1:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span>¿Estás seguro de querer eliminar en cascada?</span>
            <Button
              className="save_button"
              color="primary"
              onClick={handleRemove}
            >
              Eliminar
            </Button>
          </div>
        );

      case 2:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span>¿Estás seguro de querer reasignar por un TBR?</span>
            <Button
              className="save_button"
              color="primary"
              onClick={handleReasign}
            >
              Reasignar
            </Button>
          </div>
        );

      case 3:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={handleChangeEdit}
              options={norepobj(employeesOptions)}
              value={newManager}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40%",
              }}
              renderOption={(props: any, option: any) => {
                return (
                  <li {...props} key={option.key} value={option.value}>
                    {option.label}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%", marginBottom: "2rem" }}
                  {...params}
                  label="Empleado"
                />
              )}
            />
            <Button
              className="save_button"
              color="primary"
              onClick={handleReasign}
              disabled={newManager === ""}
            >
              Guardar
            </Button>
          </div>
        );

      case 4:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={handleChangeEditBranch}
              options={norepobj(employeesOptions)}
              value={newManagerBranch}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40%",
              }}
              renderOption={(props: any, option: any) => {
                return (
                  <li {...props} key={option.key} value={option.value}>
                    {option.label}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%", marginBottom: "2rem" }}
                  {...params}
                  label="Responsable"
                />
              )}
            />

            <TextField
              id="layer"
              disabled={isAssitant}
              variant={"filled"}
              label={order !== null && order && undefined ? "Orden" : ""}
              value={10}
              style={{ width: "20%", marginLeft: 0, marginRight: 0 }}
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
                max: 2,
              }}
              onChange={handleOrder}
              helperText="Ingresa el Orden"
            />
            <Button
              className="save_button"
              color="primary"
              onClick={handleCopyBranch}
            >
              Reasignar responsable
            </Button>
          </div>
        );

      case 5:
        return (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "row",
              }}
            >
              <TextField
                id="empl-update-range"
                label="Responsable"
                variant="filled"
                disabled
                defaultValue={rangeUpdateManager?.display_name}
              />

              <TextField
                id="layer"
                label="Rango"
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  min: 0,
                  max: 3,
                }}
                value={rango}
                onChange={handleRango}
                helperText="Ingresa el número correspondiente a su rango"
              />
            </div>
            <Button
              className="save_button"
              color="primary"
              onClick={handleUpdateRange}
            >
              Actualizar rango
            </Button>
          </div>
        );

      default:
        return <div></div>;
    }
  };

  useEffect(() => {
    const selectedEmployee = employees.find(
      (employee: any) => employee.id === props.alreadyAssignated?.id_employee
    );
    if (selectedEmployee) {
      const employeeFilteres: any = [...employees].filter(
        (employee: any) =>
          employee.id_company === selectedEmployee.id_company &&
          employee.id_department === selectedEmployee.id_department &&
          employee.active
      );
      getAllDepartments().then(setDepartamentsList);
      getAllEmployeeTeams(props.alreadyAssignated.id_company).then(
        (team: any) => {
          const mapListOrderNumber: any = employeeFilteres.map(
            (employee: any) => ({
              ...employee,
              order:
                team.find(
                  (memberTeam: any) => memberTeam.id_employee === employee.id
                )?.order ?? 0,
            })
          );

          setListOrderNumber(mapListOrderNumber);
        }
      );
    }
  }, [employees]);

  useEffect(() => {
    if (ListorderNumber.length > 0) {
      setPreparedShowModalChangeOrder(true);
    }
  }, [ListorderNumber]);

  return (
    <>
      {openModalShow && preparedShowModalChangeOrder && (
        <div style={{ height: "600px", width: "100%" }}>
          <div className="d-flex w-full my-2">
            <Button
              variant="text"
              onClick={() => setOpenModalShow(false)}
              startIcon={<KeyboardReturnIcon />}
            ></Button>
            <Typography
              style={{
                display: "flex",
                width: "85%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Asignación de orden
            </Typography>
          </div>
          {
            <>
              <DataGrid
                style={{ height: "85%" }}
                rows={ListorderNumber}
                initialState={ListorderNumber}
                columns={columns}
                disableSelectionOnClick={false}
                onCellEditCommit={(params: any) => {
                  handleCommitItemOrder(params);
                }}
                editMode="cell"
              ></DataGrid>
              <div
                className="d-flex w-full justify-content-end"
                style={{ marginTop: ".5rem" }}
              >
                <Button
                  onClick={() => {
                    handleAsignOrderAllEmployeeTeam();
                  }}
                  color="primary"
                  className="org_Button p-2"
                >
                  Guardar todos
                </Button>
              </div>
            </>
          }
        </div>
      )}
      {!openModalShow && (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 2, mx: 4, width: "40%" },
            width: "100%",
            mx: "auto",
            my: 2,
          }}
          noValidate
          autoComplete="off"
          className="text-center"
          onSubmit={handleSave}
        >
          <div className="d-flex justify-content-center pb-4 align-items-center">
            <img
              className="person-box_img"
              src={
                process.env.REACT_APP_API_BACKEND +
                "/api/v1/" +
                selectedRow.url_img
              }
            ></img>
            <div className="whiteBox d-flex flex-column align-items-center">
              <h1 className="person-box_title">{selectedRow.display_name}</h1>
              <h2 className="person-box_subtitle">{selectedRow.azure_rol}</h2>
            </div>
          </div>
          {alreadyAssignated || selectedRow.name.includes("TBR") ? (
            <div
              className="d-flex flex-column w-100 mt-5"
              style={{ gap: "20px" }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
                  gap: "10px",
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                <Button
                  className="save_button m-1 w-100"
                  color="primary"
                  onClick={() => setStep(1)}
                >
                  Borrar en cascada
                </Button>

                {!selectedRow.name.includes("TBR") ? (
                  <Button
                    className="save_button m-1 tbr w-100"
                    color="primary"
                    onClick={() => setStep(2)}
                  >
                    Reasignar por TBR
                  </Button>
                ) : (
                  <></>
                )}

                <ButtonEmployeeOptions />
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
                  gap: "10px",
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                <Button
                  className="save_button m-1 w-100"
                  color="primary"
                  onClick={() => setStep(4)}
                >
                  Reasignar responsable
                </Button>

                <Button
                  className="save_button m-1 w-100"
                  color="primary"
                  onClick={() => setStep(5)}
                >
                  Editar rango
                </Button>
                <Button
                  className="save_button m-1 w-100"
                  color="primary"
                  onClick={() => setOpenModalShow(!openModalShow)}
                >
                  Editar orden
                </Button>
              </div>

              <div>
                <Stepper />
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "flex-start",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={handleChange}
                  options={norepobj(employeesOptions)}
                  style={{ width: "35%", marginLeft: 0, marginRight: 0 }}
                  renderOption={(props: any, option: any) => {
                    return (
                      <li {...props} key={option.key} value={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        width: "100%",
                        marginBottom: "2rem",
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                      {...params}
                      label="Responsable"
                    />
                  )}
                />
                <TextField
                  id="layer"
                  disabled={isAssitant}
                  variant={!isAssitant ? "outlined" : "filled"}
                  label="Rango"
                  style={{ width: "20%", marginLeft: 0, marginRight: 0 }}
                  type={"number"}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 0,
                    max: 2,
                  }}
                  onChange={handleRango}
                  helperText="Ingresa el número correspondiente a su rango"
                />

                {
                  <TextField
                    id="layerss"
                    disabled={isAssitant}
                    variant={"filled"}
                    label={order !== null && order && undefined ? "Orden" : ""}
                    value={order}
                    style={{ width: "20%", marginLeft: 0, marginRight: 0 }}
                    type={"number"}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                      max: 2,
                    }}
                    onChange={handleOrder}
                    helperText="Ingresa el Orden"
                  />
                }
                <FormControl className="mt-3">
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    ¿Es su asistente?
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      value={true}
                      control={
                        <Checkbox
                          id="is-assistant"
                          checked={isAssitant}
                          onChange={handleAssitantOptions}
                        />
                      }
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Checkbox
                          id="is-not-assistant"
                          checked={!isAssitant}
                          onChange={handleAssitantOptions}
                        />
                      }
                      label="No"
                    />
                  </FormGroup>
                </FormControl>
                {selectedRow.display_name === "TBR" ? (
                  <TextField
                    id="azure_rol"
                    label="Azure Rol"
                    style={{ width: "30%" }}
                    onChange={handleAzureRol}
                  />
                ) : (
                  <></>
                )}
              </div>

              <Button
                className="save_button"
                color="primary"
                onClick={handleSave}
              >
                Guardar
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  );
};
