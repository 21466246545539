import axios from "axios";

import { toast } from "react-toastify";
import { IEmployee } from "../interface/services";
import { configAuth } from "../Utils/headersConfig";

export const getAllEmployee = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee/",
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => toast.error("Ha habido un error"));
};
export const updateInDT = (employeeID: number) => {
  const body = {};
  return axios
    .post(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/employee/toggleDT" +
        `?id_employee=${employeeID}`,
      body,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => toast.error("Error al actualizar la dt"));
};
export const updateEmployee = async (employee: Partial<IEmployee>) => {
  return await axios
    .put(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/employee/" +
        employee.id,
      employee,
      configAuth()
    )

    .catch((error) => toast.error("Ha habido un error"));
};
export const getOrganigrama = (id_company: string) => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigrama/?id_company=" +
        id_company,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status === 404) {
        throw 404;
      } else {
        toast.error("Ha habido un error");
      }
    });
};
export const addTeamAssignment = (
  id_manager: number,
  id_employee: any,
  layer: any,
  order: number | null = null,
  id_company: number,
  id_mongo: number,
  is_assitant: boolean,
  azure_rol: any = null
) => {
  const body: any = {
    id_manager,
    id_employee,
    id_company,
    id_mongo,
    is_assitant,
  };
  if (layer !== null) {
    body.layer = layer;
  }

  if (order !== null) {
    body.order = order;
  }

  return axios
    .post(
      `${
        process.env.REACT_APP_API_BACKEND
      }/api/v1/employee/asignacion?id_employee=${body.id_employee}&id_manager=${
        body.id_manager
      }&id_mongo=${body.id_mongo}&id_company=${body.id_company}&isAssitant=${
        body.is_assitant
      }${
        body.layer !== null && body.layer !== undefined
          ? `&layer=${body.layer}`
          : ""
      }${
        body.order !== null && body.order !== undefined
          ? `&order=${body.order}`
          : ""
      }${azure_rol ? `&azureRol=${azure_rol}` : ""}`,
      body,
      configAuth()
    )
    .catch(() => toast.error("Ha habido un error"));
};
export const updateTeamAssignment = (
  id_employee: number,
  id_company: number,
  id_mongo: string,
  new_employee?: number
) => {
  const body = {};

  return axios
    .put(
      process.env.REACT_APP_API_BACKEND +
        // `/api/v1/employee/asignacion/?id_employee=${id_employee === -1 ? null : id_employee }&id_company=${id_company}${
        `/api/v1/employee/asignacion/?id_employee=${id_employee}&id_company=${id_company}&id_mongo=${id_mongo}${
          new_employee ? `&new_employee=${new_employee}` : "&new_employee=-1"
        }`,
      body,
      configAuth()
    )
    .catch((error) => toast.error("Ha habido un error"));
};
export const updateEmployeeLayer = (
  id_employee: number,
  id_company: number,
  id_mongo: string,
  new_layer: number
) => {
  const body = {};

  return axios
    .put(
      process.env.REACT_APP_API_BACKEND +
        `/api/v1/employee/asignacion/range/?id_employee=${id_employee}&id_company=${id_company}&id_mongo=${id_mongo}&new_layer=${new_layer}`,
      body,
      configAuth()
    )
    .catch((error) => toast.error("Ha habido un error"));
};
export const duplicateEmployee = (employee: IEmployee) => {
  const body = {};

  return axios
    .post(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/employee/dup/" +
        "?id_employee=" +
        employee.id +
        "&id_company=" +
        employee.id_company,
      body,
      configAuth()
    )
    .catch((error) => toast.error("Ha habido un error"));
};
export const refreshEmployees = () => {
  const body = {};
  return axios
    .put(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee/refresh/",
      body,
      configAuth()
    )
    .then(() => toast.success("Actualización completada."))
    .catch(() => toast.error("Error al actualizar"));
};

export const deleteTBR = async (id: number, mongo_id: any) => {
  return await axios
  .delete(
    process.env.REACT_APP_API_BACKEND + `/api/v1/employee/TBR/${id}?mongo_id=${mongo_id}`,
    configAuth()
  )
}